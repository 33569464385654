import * as React from 'react';

const CoderSvg: React.FC = props => (
  <svg
    data-name="Layer 1"
    width={1076.06371}
    height={755.2279}
    viewBox="0 0 1076.06371 755.2279"
    {...props}
  >
    <path
      d="M926.114 774.81c-6.985 26.597-31.46 43.219-31.46 43.219s-13.15-26.502-6.165-53.1 31.46-43.22 31.46-43.22 13.15 26.502 6.165 53.1z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path
      d="M915.52 769.183c-19.563 19.327-21.751 48.83-21.751 48.83s29.528-1.83 49.09-21.158 21.752-48.83 21.752-48.83-29.529 1.83-49.091 21.158z"
      transform="translate(-61.968 -72.386)"
      fill="#276749"
    />
    <path
      d="M206.702 674.194h36.842l90.787-35.525s64.473-26.316 59.21 23.684-14.474 117.103-14.474 117.103-28.946-13.158-44.736-9.21-5.263-80.262-5.263-80.262-128.945 61.84-140.787 53.946-14.473-63.157-14.473-63.157z"
      transform="translate(-61.968 -72.386)"
      fill="#2f2e41"
    />
    <path
      d="M206.702 674.194h36.842l90.787-35.525s64.473-26.316 59.21 23.684-14.474 117.103-14.474 117.103-28.946-13.158-44.736-9.21-5.263-80.262-5.263-80.262-128.945 61.84-140.787 53.946-14.473-63.157-14.473-63.157z"
      transform="translate(-61.968 -72.386)"
      opacity={0.1}
    />
    <path
      d="M264.746 558.82l44.586 64.06 103.945 86.84s96.051 22.368 82.893 38.157-93.419-15.789-93.419-15.789-119.734-86.84-123.682-93.42-47.367-68.419-47.367-68.419z"
      transform="translate(-61.968 -72.386)"
      fill="#a0616a"
    />
    <path
      d="M264.746 558.82l44.586 64.06 103.945 86.84s96.051 22.368 82.893 38.157-93.419-15.789-93.419-15.789-119.734-86.84-123.682-93.42-47.367-68.419-47.367-68.419z"
      transform="translate(-61.968 -72.386)"
      opacity={0.1}
    />
    <path
      d="M238.28 507.092l28.947 56.578s-6.578 27.631-17.105 30.263-55.262-34.21-55.262-34.21z"
      transform="translate(-61.968 -72.386)"
      fill="#d0cde1"
    />
    <path
      d="M238.28 507.092l28.947 56.578s-6.578 27.631-17.105 30.263-55.262-34.21-55.262-34.21z"
      transform="translate(-61.968 -72.386)"
      opacity={0.1}
    />
    <path
      d="M591.42 644.9l8.55 35.272a2.203 2.203 0 01-.043 1.19L561.14 802.574a2.203 2.203 0 01-4.288-.423l-5.365-47.22a2.203 2.203 0 01.094-.93l35.603-109.264a2.203 2.203 0 014.235.163z"
      transform="translate(-61.968 -72.386)"
      fill="#2f2e41"
    />
    <path
      fill="#f2f2f2"
      d="M526.306 575.493L534.2 605.756 499.99 716.28 492.096 682.07 526.306 575.493z"
    />
    <path
      d="M415.909 812.35c1.315 3.947 136.84-1.316 138.155-2.632a28.56 28.56 0 002.895-5.263 198.144 198.144 0 002.368-5.263l-5.263-42.604-134.208-4.764s-3.355 36.105-4.04 52.631c-.17 4.171-.17 7.105.093 7.895z"
      transform="translate(-61.968 -72.386)"
      fill="#2f2e41"
    />
    <path
      opacity={0.1}
      d="M474.991 689.965L477.622 726.806 418.413 726.806 418.413 689.965 474.991 689.965z"
    />
    <path
      opacity={0.1}
      d="M398.677 701.806L399.03 701.736 397.361 717.596 364.467 717.596 364.467 701.806 398.677 701.806z"
    />
    <path
      d="M415.909 812.35c1.315 3.947 136.84-1.316 138.155-2.632a28.56 28.56 0 002.895-5.263H415.817c-.171 4.171-.171 7.105.092 7.895z"
      transform="translate(-61.968 -72.386)"
      opacity={0.1}
    />
    <circle cx={196.04888} cy={357.07618} r={59.20939} fill="#a0616a" />
    <path
      d="M254.07 482.093s-19.737 34.21-23.684 52.63-59.21-36.841-59.21-36.841l-7.236-19.079s51.973-24.341 48.025-44.078 42.105 47.368 42.105 47.368zM264.596 584.722l53.946 71.052 128.945 96.05s93.42 19.737 72.367 34.21-81.577-13.157-81.577-13.157-130.26-69.736-157.892-98.683-78.946-77.63-78.946-77.63z"
      transform="translate(-61.968 -72.386)"
      fill="#a0616a"
    />
    <path
      d="M192.229 695.247l15.789 25 94.231-29.647c20.676-6.504 43.348-4.11 61.687 7.443 16.447 10.361 26.973 26.808 3.29 51.15-47.368 48.683-78.946 22.368-78.946 22.368s-159.208 74.998-185.523 38.157-27.631-55.262-27.631-55.262 101.314-63.157 117.103-59.21z"
      transform="translate(-61.968 -72.386)"
      fill="#2f2e41"
    />
    <path
      d="M389.593 782.087s42.105 27.631-7.894 40.789-86.84-5.263-86.84-5.263-43.42 0-43.42-22.368 14.473-25 14.473-25l44.736 6.579s53.946-14.473 78.945 5.263z"
      transform="translate(-61.968 -72.386)"
      fill="#d0cde1"
    />
    <path
      d="M306.59 414.568c4.03 1.796 8.025 3.923 12.407 4.444s9.37-1.008 11.444-4.903c1.12-2.104 1.247-4.578 1.3-6.96.158-7.226-.364-15.037-4.943-20.628-2.914-3.559-7.168-5.802-10.184-9.274-2.16-2.488-3.6-5.503-5.148-8.412-5.866-11.017-14.551-21.605-26.622-24.781-5.04-1.327-10.328-1.262-15.54-1.188l-30.396.429c-4.92.069-9.914.15-14.623 1.573-9.784 2.959-16.908 11.186-23.432 19.055-4.882 5.886-9.776 11.834-13.44 18.547a65.079 65.079 0 00-7.713 31.886 29.833 29.833 0 001.092 8.438 46.822 46.822 0 003.317 7.284c5.7 11.197 9.085 25.182 2.665 35.982 11.152-4.555 22.186-10.528 29.542-20.067 3.291-4.268 5.872-9.263 10.258-12.395s11.532-3.403 14.346 1.193a12.857 12.857 0 011.45 6.022c.206 3.45.2 7.033 1.663 10.164s4.947 5.658 8.274 4.72c5.728-1.613 5.18-10.405 9.592-14.4 3.351-3.033 8.52-2.453 12.826-3.83 5.016-1.604 8.78-5.95 10.924-10.76 1.63-3.655 1.606-13.222 4.69-15.202 3.869-2.483 12.665 1.464 16.25 3.063z"
      transform="translate(-61.968 -72.386)"
      fill="#2f2e41"
    />
    <path
      d="M159.24 472.562a15.616 15.616 0 018.128.02c8.446 2.268 27.38 8.271 31.44 17.405 5.263 11.842 23.683 30.263 23.683 30.263s25 25 19.737 42.104-25 36.842-25 36.842 5.263 81.577-21.052 102.63-36.841 6.578-36.841 26.315-81.578 81.577-97.367 43.42c0 0 7.895-71.051 5.263-96.05-2.593-24.64 1.203-178.366 92.009-202.949z"
      transform="translate(-61.968 -72.386)"
      fill="#d0cde1"
    />
    <path
      d="M201.44 505.777s80.26 59.209 65.787 84.208c0 0-48.683 23.684-59.209 22.368s-51.315-47.367-61.84-51.314-15.79-74.999 55.261-55.262z"
      transform="translate(-61.968 -72.386)"
      fill="#d0cde1"
    />
    <circle cx={498.06371} cy={237.45791} r={65} fill="#f2f2f2" />
    <path
      d="M1096.898 225.427H732.649v-76.22h364.249zm-362.644-1.605h361.04v-73.01h-361.04z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path
      fill="#276749"
      d="M645.80936 92.06506H1008.45343V166.67988H645.80936z"
    />
    <circle cx={416.56486} cy={15.24386} r={5.61624} fill="#276749" />
    <circle cx={435.8203} cy={15.24386} r={5.61624} fill="#276749" />
    <circle cx={455.07573} cy={15.24386} r={5.61624} fill="#276749" />
    <path
      d="M1138.032 512.052H462.487V72.386h675.545zm-673.94-1.605h672.335V73.991H464.092z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path
      fill="#3f3d56"
      d="M401.3211 24.33804H1075.2613999999999V25.94266H401.3211z"
    />
    <path
      d="M480.138 91.641a6.418 6.418 0 116.418-6.418 6.426 6.426 0 01-6.418 6.418zm0-11.232a4.814 4.814 0 104.814 4.814 4.82 4.82 0 00-4.814-4.814zM499.393 91.641a6.418 6.418 0 116.419-6.418 6.426 6.426 0 01-6.419 6.418zm0-11.232a4.814 4.814 0 104.814 4.814 4.82 4.82 0 00-4.814-4.814zM518.649 91.641a6.418 6.418 0 116.418-6.418 6.426 6.426 0 01-6.418 6.418zm0-11.232a4.814 4.814 0 104.813 4.814 4.82 4.82 0 00-4.813-4.814z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path fill="#3f3d56" d="M612.10922 25.14035H613.71384V439.486H612.10922z" />
    <path
      fill="#f2f2f2"
      d="M688.73293 228.85889H806.67248V244.90509H688.73293z"
    />
    <path
      fill="#f2f2f2"
      d="M874.06652 228.85889H992.0060699999999V244.90509H874.06652z"
    />
    <path
      d="M875.861 310.07H756.317v-17.65h119.544zm-117.94-1.604h116.336v-14.442H757.922z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path
      fill="#f2f2f2"
      d="M688.73293 353.21693H806.67248V369.26313H688.73293z"
    />
    <path
      d="M875.861 434.428H756.317v-17.65h119.544zm-117.94-1.604h116.336v-14.442H757.922z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path
      fill="#f2f2f2"
      d="M689.13409 289.43329H992.40723V305.47949H689.13409z"
    />
    <path
      d="M1061.596 372.25H756.718v-17.651h304.878zm-303.273-1.605h301.669v-14.442H758.323zM1061.195 310.07H941.651v-17.65h119.544zm-117.94-1.604h116.335v-14.442H943.255zM571.032 357.844a66 66 0 1166-66 66.075 66.075 0 01-66 66zm0-130a64 64 0 1064 64 64.073 64.073 0 00-64-64z"
      transform="translate(-61.968 -72.386)"
      fill="#3f3d56"
    />
    <path
      fill="#3f3d56"
      d="M748.06371 744.45791H1031.06371V746.45791H748.06371z"
    />
  </svg>
);

export default CoderSvg;
